/**
 * This enum contains keys to be used with IStorage / Storage.
 * If you're adding the members to this enum, please make sure to use the shortest strings possible.
 * If the data is stored in the cookies, every saved byte counts due to the cookie size limitations!
 */

export enum StorageKeys {
	SESSION_ID = "a",
	EMAIL = "b",
	USER_ID = "c",
	DATE_JOINED = "d",
	LOCALE = "e",
	EXTERNAL_ID = "f",
	HASH_ID = "h",
	USER_ROLE = "i",
	TOKEN_EXPIRY = "j",
	USER_NAME = "k",
	IS_ACTIVE_USER = "l",
	VIDEO_CALL_STATE = "vc_s",
	BROWSER_LOCK = "vc_bl",
	PUSH_LOCAL_USER_REQUIRED = "push_lu_req",
	IS_PORTAL_IN_FOCUS = "p_f",
	OFFLINE_ERROR_LOGS = "e_q",
	FEATURE_FLAGS = "f_f",
	LAST_INTERACTION_FLAG = "li_f",
	LAST_INTERACTION_SYNCED_IDS = "li_s",
	FRESHDESK_DID_ASK_SSON_SAML_REQUEST = "dasr",
	SHOULD_JOIN_ORG = "should_join_org",
	BLOCK_LOGIN = "b_l",
	LAST_LOGIN = "la_l",

	// TODO: Remove this when corona scale-up organization migration is done.
	SHOULD_JOIN_NEW_ORG = "should_join_new_org",

	/**
	 * Used for muting noisy log outputs.
	 * @See `src/ts/utils/muteLog/loggable.ts`
	 */
	LOG_BLACKLIST = "nd_log_blacklist",

	/**
	 * Call background settings
	 */
	CALL_BACKGROUND_SETTINGS = "call_bg_settings",

	/**
	 * As there are no endpoints to get the logged in roles/permissions,
	 * we store them in storage now.
	 */
	USER_PERMISSIONS = "user_permissions",
}

/**
 * List of items that need to be removed when user logged out.
 */
export const removableStorageKeys = [
	StorageKeys.EMAIL,
	StorageKeys.HASH_ID,
	StorageKeys.DATE_JOINED,
	StorageKeys.USER_ID,
	StorageKeys.USER_NAME,
	StorageKeys.IS_ACTIVE_USER,
	StorageKeys.EXTERNAL_ID,
	StorageKeys.SESSION_ID,
	StorageKeys.USER_ROLE,
	StorageKeys.TOKEN_EXPIRY,
	StorageKeys.SHOULD_JOIN_ORG,
	StorageKeys.VIDEO_CALL_STATE,
	StorageKeys.FRESHDESK_DID_ASK_SSON_SAML_REQUEST,
	StorageKeys.LAST_LOGIN,
	StorageKeys.USER_PERMISSIONS,

	// Contain information about last interaction times sync to BE
	StorageKeys.LAST_INTERACTION_SYNCED_IDS,
	// TODO: Remove this when the corona scale-up organization migration is done.
	StorageKeys.SHOULD_JOIN_NEW_ORG,
];
