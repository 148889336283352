////////////////////////////////////////////////////////////////////////////////////
//      NB: This is a DEV BUILD configuration using staging backend servers!
////////////////////////////////////////////////////////////////////////////////////

//@ts-ignore
import {AppVersion} from "./config/AppVersion";
//@ts-ignore
import {Environment} from "./config/Environment";
//@ts-ignore
import {LogLevels} from "../../logger/LogLevels";
//@ts-ignore
import {TIME} from "../constants/time";
import {GOALIE_JS__CONFIG, LanguageTag, SenseServerEnvironment} from "@sense-os/goalie-js/dist";

const baseUrl = process.env.BASE_URL || "https://localhost:9010";
const apiBaseUrl = process.env.API_BASE_URL || "https://alpha.niceday.app/api/v1/";
const chatUrl = process.env.CHAT_URL || "wss://chat-alpha.niceday.app/ws";
const chatDomain = process.env.CHAT_DOMAIN || "alpha.nice-day.nl";
const chatPubSubServer = process.env.CHAT_PUBSUB_SERVER || "pubsub.alpha.nice-day.nl";
const blogUrl = "https =//niceday.app/wp-json/wp/v2/";

/**
 * Here, we set the central configuration for goalie-js as part of the build config
 */
GOALIE_JS__CONFIG.setAllConfig({
	apiBaseUrl,
	chatUrl,
	chatDomain,
	chatPubSubServer,
	blogUrl,
	environment: SenseServerEnvironment.Alpha,
});

export class AppConfig {
	/** The default level of logging to use in the app */
	public static readonly logLevel: LogLevels = LogLevels.DEBUG;

	/** Name of the target environment */
	public static readonly env: Environment = Environment.DEV;

	/** Version of the application */
	public static readonly version: string = AppVersion.VERSION + "-" + AppConfig.env;

	/**  Data Source Name value to be used with the Sentry SDK */
	public static readonly sentryDSN: string = "https://d7ebddfa92f1492fa18ddd7e9f5ac83f@sentry.sense-os.nl/35";

	/** URL for long polling to check internet connection */
	public static readonly internetConnectionLongPollingUrl: string =
		"https://niceday-staging.s3.eu-central-1.amazonaws.com/blank.txt";

	/** Inter-window communication whitelisted domains */
	public static readonly IWC_URLs: string[] = [baseUrl];

	/** The key for Segment Analytics */
	public static readonly SEGMENT_ANALYTICS_KEY: string = "IH7YnDvnge5Ae1zCbuk9sr8tqmv1nLs1";

	/** After CALL_TIMEOUT_SECONDS we assume that the other party was away or did not feel like picking the call up. */
	public static readonly CALL_TIMEOUT_SECONDS: number = 25;

	/**
	 * After call, portal might show a form to rate the quality of the call.
	 * This constant here is the minimum duration for a call before portal will show that form.
	 */
	public static readonly MIN_DURATION_FOR_RATE_CALL_FORM_SECONDS: number = 10;

	/** Minimum duration for meeting note confirmation dialog to show up after video calling */
	public static readonly MIN_MEETING_NOTES_DURATION_SECONDS: number = 10;

	/**  In case of doubt which language/locale to use for rendering the UI, the `DEFAULT_LOCALE` will be used. */
	public static readonly DEFAULT_LOCALE: LanguageTag = LanguageTag.NL;

	/** After that many seconds the local user is considered to be inactive in the chat */
	public static readonly ONLINE_TIMEOUT_SECONDS: number = 10;

	public static readonly isDev: boolean = true;
	public static readonly isAlpha: boolean = false;
	public static readonly isBeta: boolean = false;
	public static readonly isProduction: boolean = false;

	/**
	 * Temporary organization for corona scale-up organization migrations
	 * TODO: Remove this when the migration is done.
	 */
	public static readonly CORONA_SCALEUP_ORGANIZATION: number[] = [
		229, // Parnassia
	];

	/**
	 * List of organization ids for researcher users.
	 * If user belongs to this organization, the logged in user will be considered as `Researcher`.
	 */
	public static readonly RESEARCHER_ORGANIZATION_IDS: number[] = [235];

	/** How often the portal checks whether there's a new version released  */
	public static readonly VERSION_CHECK_INTERVAL_MS = TIME.TWO_MINUTES_MS;

	/** Some features are limited to NiceDay organization */
	public static readonly NICEDAY_ORG_ID = 1;
	public static readonly SOL_ORG_ID = 231;
	public static readonly SOLO_THERAPIST_ORG = 243;
	public static readonly NDT_ORG = -1;

	/** Base URL for NiceDay app's universal link. */
	public static readonly APP_BASE_URL = "https://get-alpha.niceday.app";

	/** Key for product fruits workspace code */
	public static readonly PRODUCTFRUITS_WORKSPACE_KEY: string = "D80jtJ8RALlvclQm";

	/** core tracker ids */
	public static readonly BEHAVIOR_EXPERIMENT_ID = 67;
	public static readonly CHECK_IN_ID = 63;
	public static readonly DIARY_NOTE_ID = 62;
	public static readonly MEETING_NOTES_ID = 65;
	public static readonly PSYCHOEDUCATION_ID = 68;
	public static readonly THOUGHT_RECORD_ID = 66;
	public static readonly STEP_COUNT_ID = 64;
}
